<template>
  <div id="app">
    <div>
      <button class="flagButton mr-3 mt-2" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
        <img class="flag mt-1" style="height: 15px; width: 25px" v-if="entry.title === 'Eesti'" src="../assets/images/estonia.png">
        <img class="flag mt-1" style="height: 15px; width: 25px" v-else src="../assets/images/english.png">
        {{ entry.title }}
      </button>
    </div>
  </div>
</template>

<script>
import i18n from '../plugins/i18n'

export default {
  data () {
    return {
      msg: String,
      languages: [
        { flag: 'ee', language: 'est', title: 'Eesti' },
        { flag: 'gb', language: 'eng', title: 'English' }
      ]
    }
  },
  methods: {
    changeLocale (locale) {
      if (locale === 'est') {
        this.$store.state.selectedLanguage = 'est'
        i18n.locale = 'est'
      } else if (locale === 'eng') {
        this.$store.state.selectedLanguage = 'eng'
        i18n.locale = 'eng'
      }
    }
  }
}
</script>

<style>
.flag {
  width: 25px;
  height: 15px;
}
.flagButton {
  width: 90px;
  height: 30px;
}
</style>
