<template>
  <div>
    <!-- Maximize, on mobile -->
    <div v-if="minimized" class="box overlay form" style="width: fit-content">
      <div class="field">
        <b-button class="is-light" @click="minimized = false"><i class="fas fa-arrow-down"></i>{{ $t('formShowButton') }}</b-button>
      </div>
    </div>
    <div v-if="!minimized" class="box overlay form">
      <form v-on:submit.prevent>
        <!-- Responses and loader -->
        <b-loading :is-full-page="false" v-model="loading" :can-cancel="true"></b-loading>

        <!-- Minimize, on mobile -->
        <div class="field is-hidden-desktop">
          <b-button class="is-light is-fullwidth" @click="minimized = true"><i class="fas fa-arrow-up"></i>{{ $t('formHideButton') }}</b-button>
        </div>

        <!-- Contact details -->
        <span class="is-size-4">1. {{ $t('formFirstTitle') }}</span>
        <div class="field field-body">
          <!-- Name -->
          <div class="field">
            <label class="label is-small">* {{ $t('formPersonName') }}</label>
            <div class="control">
              <input name="name" class="input is-small" type="text" placeholder="" v-model="form.name">
            </div>
          </div>
          <!-- Company -->
          <div class="field">
            <label class="label is-small">{{ $t('formCompanyName') }}</label>
            <div class="control">
              <input name="company" class="input is-small" type="text" placeholder="" v-model="form.company">
            </div>
          </div>
        </div>

        <div class="field field-body">
          <!-- E-mail -->
          <div class="field">
            <label class="label is-small">* {{ $t('formEmail') }}</label>
            <div class="control">
              <input name="email" class="input is-small" type="email" placeholder="" v-model="form.email">
            </div>
          </div>
          <!-- Phone -->
          <div class="field">
            <label class="label is-small">* {{ $t('formPhoneNumber') }}</label>
            <div class="control">
              <input name="phone" class="input is-small" type="tel" placeholder="" v-model="form.phone">
            </div>
          </div>
        </div>

        <div class="field">
          <textarea name="extra" class="textarea is-small" rows="2" :placeholder="$t('formTextboxPlaceholder')" v-model="form.extra"></textarea>
          <span class="is-size-7" style="margin-bottom: 15px;">* {{ $t('formRequiredStar') }}</span>
        </div>

        <hr class="divider">
        <!-- Services -->
        <span class="is-size-4">2. {{ $t('formSecondTitle') }}</span>
        <!-- Services -->
        <div class="field">
          <label class="label is-small">
            {{ $t('formDesiredService') }}
          </label>
          <div class="control">
          <span class="tag is-info mr-1" v-for="type in form.types" :key="type">
            {{ types.find(selected => selected.value === type).title }}
          </span>
          </div>
          <b-button class="is-light is-small is-info mt-1" @click="modal = true">
            <span v-if="form.types.length === 0">{{ $t('formPickService') }}</span>
            <span v-if="form.types.length > 0">{{ $t('formChangePickedService') }}</span>
          </b-button>
        </div>

        <!-- Extras -->
        <div class="field" v-if="form.extras.length > 0">
          <label class="label is-small">{{ $t('selectedExtras') }}</label>
          <div class="control">
          <span class="tag is-info mr-1" v-for="extra in form.extras" :key="extra">
            {{ extras.find(selected => selected.value === extra).title }}
          </span>
          </div>
        </div>

        <div class="field field-body">
          <!-- Resolution -->
          <div class="field">
            <label class="label is-small">{{ $t('formResolution') }}</label>
            <div class="control">
              <div class="select is-small is-fullwidth">
                <select v-model="form.resolution">
                  <option v-for="resolution in resolutions" :key="resolution.value" :value="resolution.value">{{ resolution.value }}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- Ref. system -->
          <div class="field">
            <label class="label is-small">{{ $t('formCoordinateSystem') }}</label>
            <div class="control">
              <div class="select is-small is-fullwidth">
                <select v-model="form.system">
                  <option v-for="system in systems" :key="system.value" :value="system.value">{{ system.value }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <hr class="divider">
        <!-- Map -->
        <h1 class="is-size-4">3. {{ $t('formThirdTitle') }}</h1>
        <DrawingHelp />

        <div class="field">
          <div class="control">
            <button class="button is-info is-large is-fullwidth" @click="submit()">{{ $t('formSubmit') }}</button>
          </div>
        </div>

        <div class="field" style="font-size: 11px; text-align: right;">
          &copy; 3Di 2022 &bull; {{ $t('formContactUs') }} <a href="tel:+372 5688 4466">+372 5688 4466</a>
        </div>
      </form>
    </div>
    <!-- Services selection modal -->
    <b-modal v-model="modal" scroll="keep">
      <div style="padding: 15px 10px;overflow: hidden;background:#ffffff;">
        <div class="columns" style="border-bottom: 1px #cccccc solid;" v-for="type in types" :key="type.value">
          <div class="column is-two-fifths">
            <b-image
              :src="require('@/assets/images/' + type.value + '.png')"
              ratio="3by1"
            ></b-image>
          </div>
          <div class="column">
            <label class="checkbox">
              <input type="checkbox" :value="type.value" v-model="form.types">
              <b>&nbsp;{{ type.title }}</b><p></p>
              <span v-html="type.desc"></span>
            </label>
            <div style="margin-top: 10px;" >
              <label class="checkbox is-small" v-for="extra in extras.filter(extra => (extra.main === type.value))" :key="extra.value">
                <input type="checkbox" :value="extra.value" v-model="form.extras">
                {{ extra.title }}
              </label>
            </div>
          </div>
        </div>
        <b-button class="button is-info is-fullwidth" @click="modal = false">{{ $t('pickServiceModalSubmit') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DrawingHelp from './DrawingHelp.vue'

export default {
  components: { DrawingHelp },
  data () {
    return {
      modal: false,
      minimized: false,
      loading: false,
      types: [
        { value: 'photo', title: '', desc: '' },
        { value: 'aero_measuring', title: '', desc: '' },
        { value: 'orto', title: '', desc: '' },
        { value: 'dem', title: '', desc: '' },
        { value: 'point_cloud', title: '', desc: '' },
        { value: '3d', title: '', desc: '' },
        { value: 'topo', title: '', desc: '' },
        { value: 'siteview', title: '', desc: '' },
        { value: 'thermal', title: '', desc: '' },
        { value: 'lidar', title: '', desc: '' }
      ],
      extras: [
        { value: 'orto_wms', title: 'WMS teenus', main: 'orto' }
      ],
      resolutions: [
        { value: '1 cm/piksel' },
        { value: '1.5 cm/piksel' },
        { value: '2 cm/piksel' },
        { value: '2.5 cm/piksel' }
      ],
      systems: [
        { value: 'L-EST´97' },
        { value: 'WGS84' }
      ],
      form: {
        types: [],
        extras: [],
        resolution: '',
        system: 'L-EST´97',
        name: '',
        company: '',
        email: '',
        phone: '',
        extra: ''
      }
    }
  },
  computed: {
    selectedLanguage () {
      return this.$store.state.selectedLanguage
    }
  },
  mounted () {
    this.types[0].title = this.$t('firstPickServiceModalTitle')
    this.types[0].desc = this.$t('firstPickServiceModalExplanation')
    this.types[1].title = this.$t('secondPickServiceModalTitle')
    this.types[1].desc = this.$t('secondPickServiceModalExplanation')
    this.types[2].title = this.$t('thirdPickServiceModalTitle')
    this.types[2].desc = this.$t('thirdPickServiceModalExplanation')
    this.extras[0].title = this.$t('thirdPickServiceModalCheckbox')
    this.types[3].title = this.$t('fourthPickServiceModalTitle')
    this.types[3].desc = this.$t('fourthPickServiceModalExplanation')
    this.types[4].title = this.$t('fifthPickServiceModalTitle')
    this.types[4].desc = this.$t('fifthPickServiceModalExplanation')
    this.types[5].title = this.$t('sixthPickServiceModalTitle')
    this.types[5].desc = this.$t('sixthPickServiceModalExplanation')
    this.types[6].title = this.$t('seventhPickServiceModalTitle')
    this.types[6].desc = this.$t('seventhPickServiceModalExplanation')
    this.types[7].title = this.$t('eightPickServiceModalTitle')
    this.types[7].desc = this.$t('eightPickServiceModalExplanation')
    this.types[8].title = this.$t('ninthPickServiceModalTitle')
    this.types[8].desc = this.$t('ninthPickServiceModalExplanation')
    this.types[9].title = this.$t('tenthPickServiceModalTitle')
    this.types[9].desc = this.$t('tenthPickServiceModalExplanation')
    this.resolutions[0].value = this.$t('formResolutionDropDownFirstPick')
    this.resolutions[1].value = this.$t('formResolutionDropDownSecondPick')
    this.resolutions[2].value = this.$t('formResolutionDropDownThirdPick')
    this.resolutions[3].value = this.$t('formResolutionDropDownFourthPick')
    this.form.resolution = this.$t('formResolutionDropDownFirstPick')
  },
  watch: {
    selectedLanguage () {
      this.types[0].title = this.$t('firstPickServiceModalTitle')
      this.types[0].desc = this.$t('firstPickServiceModalExplanation')
      this.types[1].title = this.$t('secondPickServiceModalTitle')
      this.types[1].desc = this.$t('secondPickServiceModalExplanation')
      this.types[2].title = this.$t('thirdPickServiceModalTitle')
      this.types[2].desc = this.$t('thirdPickServiceModalExplanation')
      this.extras[0].title = this.$t('thirdPickServiceModalCheckbox')
      this.types[3].title = this.$t('fourthPickServiceModalTitle')
      this.types[3].desc = this.$t('fourthPickServiceModalExplanation')
      this.types[4].title = this.$t('fifthPickServiceModalTitle')
      this.types[4].desc = this.$t('fifthPickServiceModalExplanation')
      this.types[5].title = this.$t('sixthPickServiceModalTitle')
      this.types[5].desc = this.$t('sixthPickServiceModalExplanation')
      this.types[6].title = this.$t('seventhPickServiceModalTitle')
      this.types[6].desc = this.$t('seventhPickServiceModalExplanation')
      this.types[7].title = this.$t('eightPickServiceModalTitle')
      this.types[7].desc = this.$t('eightPickServiceModalExplanation')
      this.types[8].title = this.$t('ninthPickServiceModalTitle')
      this.types[8].desc = this.$t('ninthPickServiceModalExplanation')
      this.types[9].title = this.$t('tenthPickServiceModalTitle')
      this.types[9].desc = this.$t('tenthPickServiceModalExplanation')
      this.resolutions[0].value = this.$t('formResolutionDropDownFirstPick')
      this.resolutions[1].value = this.$t('formResolutionDropDownSecondPick')
      this.resolutions[2].value = this.$t('formResolutionDropDownThirdPick')
      this.resolutions[3].value = this.$t('formResolutionDropDownFourthPick')
      this.form.resolution = this.$t('formResolutionDropDownFirstPick')
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.form.area = this.$store.state.area
      this.axios.post(this.$store.state.server, {
        data: this.form
      })
        .then(response => {
          // Clear map if request was sent
          if (response.data.result === 1) {
            this.$buefy.dialog.alert({
              title: this.$t('successModalTitle'),
              message: this.$t('successModalText'),
              type: 'is-success',
              confirmText: this.$t('sucessModalButtonText'),
              ariaModal: true
            })
            this.$store.commit('setArea', null)
          } else {
            this.$buefy.dialog.alert({
              title: this.$t('failedModalTitle'),
              message: this.$t('failedModalText'),
              type: 'is-danger',
              confirmText: this.$t('failedModalButtonText'),
              ariaModal: true
            })
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    }
  }
}
</script>
