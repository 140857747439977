<template>
  <vl-map :pixel-ratio="1" :load-tiles-while-animating="true" :load-tiles-while-interacting="true" class="map">
    <vl-view :max-resolution="3000" :zoom.sync="zoom" :center.sync="center"></vl-view>

    <vl-layer-vector :z-index=1>
      <!-- Drawing source -->
      <vl-source-vector :features.sync="areas" ident="the-source"></vl-source-vector>

      <!-- Drawing -->
      <vl-interaction-draw :active="canDraw" type="Polygon" source="the-source" @drawstart="onDrawStart" @drawend="onDrawEnd">
        <vl-style-box>
          <vl-style-stroke color="rgba(0,37,62,0.8)"></vl-style-stroke>
          <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
        </vl-style-box>
      </vl-interaction-draw>

      <!-- Modify area -->
      <vl-interaction-select ident="the-source"></vl-interaction-select>
      <vl-interaction-modify source="the-source" @modifystart="onModifyStart" @modifyend="onModifyEnd"></vl-interaction-modify>

      <!-- Drawn area -->
      <vl-style-box>
        <vl-style-stroke color="rgba(0,74,152,0.8)"></vl-style-stroke>
        <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
      </vl-style-box>
    </vl-layer-vector>

    <!-- Map tiles -->
    <!--
    <vl-layer-tile id="osm" :z-index=0>
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>
    -->
    <vl-layer-tile id="bingmaps">
      <vl-source-bingmaps :api-key="apiKey" :imagery-set="imagerySet"></vl-source-bingmaps>
    </vl-layer-tile>
    <!-- Maaameti WMS test -->
    <!--
    <vl-layer-image>
      <vl-source-image-wms :ratio="1" :hidpi="false" :size="[11399, 7163]" :url="url" :version="params.VERSION" :layers="params.LAYERS" projection="EPSG:3301" server-type="geoserver" :ext-params="params"></vl-source-image-wms>
    </vl-layer-image>
    -->
  </vl-map>
</template>

<script>

export default {
  data () {
    return {
      apiKey: 'AsNGdj1H_Pb9XsaA9cLj0GT7c0PAl6zUO65j9SJpHy2jpA-mu5RL3m5lpgQDPuN0',
      imagerySet: 'AerialWithLabels',
      ratio: 1,
      // projection: 'EPSG:3301',
      url: 'https://kaart.maaamet.ee/wms/alus?',
      zoom: 2,
      params: {
        WIDTH: 3000,
        HEIGHT: 3000,
        LAYERS: 'of10000',
        VERSION: '1.1.1'
      },
      center: [25.7648883, 58.587827],
      // center: [550000, 6520000],
      areas: [],
      modifyingAreas: {},
      modifiedAreas: [],
      canDraw: true
    }
  },
  watch: {
    '$store.state.area': function () {
      if (!this.$store.state.area) {
        this.areas = []
        this.$store.commit('setDrawing', false)
        this.canDraw = true
      } else {
        this.canDraw = false
      }
    }
  },
  mounted () {
    this.ratio = window.devicePixelRatio
  },
  methods: {
    onDrawStart () {
      this.$store.commit('setDrawing', true)
      // console.log('Drawing!')
    },
    onDrawEnd () {
      this.$store.commit('setDrawing', true)
      this.$store.commit('setArea', this.areas[0].geometry.coordinates[0])
      // console.log(this.areas[0].geometry.coordinates[0])
    },
    onModifyStart (evt) {
      this.modifyingAreas = {}
      this.modifiedAreas = []
      evt.features.forEach(feature => {
        this.modifyingAreas[feature.getId()] = feature.getRevision()
      })
    },
    onModifyEnd (evt) {
      this.modifiedAreas = evt.features.getArray().reduce((modified, feature) => {
        if (this.modifyingAreas[feature.getId()] !== feature.getRevision()) {
          modified.push(feature.getId())
        }
        return modified
      }, [])
      this.$store.commit('setArea', this.areas[0].geometry.coordinates[0])
    }
  }
}
</script>
