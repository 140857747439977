import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  est: {
    // Show and hide form buttons
    formShowButton: 'Kuva vorm',
    formHideButton: 'Peida vorm',
    // First part of the form
    formFirstTitle: 'Teie kontaktandmed',
    formPersonName: 'Teie nimi:',
    formCompanyName: 'Ettevõtte nimi:',
    formEmail: 'E-posti aadress:',
    formPhoneNumber: 'Telefoninumber:',
    formTextboxPlaceholder: 'Täpsustav lisainfo seoses päringuga',
    formRequiredStar: 'Kohustuslik väljad',
    // Second part of the form
    formSecondTitle: 'Teenuse valimine',
    formDesiredService: 'Soovitud teenused:',
    formPickService: 'Vali teenus:',
    formChangePickedService: 'Muuda valikut',
    // Extras
    selectedExtras: 'Valitud lisateenused',
    // Pick service modal
    firstPickServiceModalTitle: 'Foto objektist / Progressifoto',
    firstPickServiceModalExplanation: 'Droonilt tehtud üksik pilt või progressi jälgiv foto seeria olukordade/etappide fikseerimiseks.<br>Edastatakse: fotod.',
    secondPickServiceModalTitle: 'Koordineeritud fotod objektist',
    secondPickServiceModalExplanation: 'Aeropildistamine drooniga fotogramm-meetriliseks töötluseks.<br>Edastatakse: koordineeritud pildid.',
    thirdPickServiceModalTitle: 'Ortofotomosaiik',
    thirdPickServiceModalExplanation: 'Fotogramm-meetriline aeromõõdistamine ja fotode töötlemine ortofotomosaiigiks.<br>Edastatakse: koordineeritud ortofotomosaiik GeoTiff fail.',
    thirdPickServiceModalCheckbox: 'WMS teenus',
    fourthPickServiceModalTitle: 'DEM foto',
    fourthPickServiceModalExplanation: 'Aeromõõdistatud ala digitaalne tasapinnaline kõrgusmudel (põhineb DEM mudelil).<br>Edastatakse: koordineeritud foto kõrgusliku infoga.',
    fifthPickServiceModalTitle: 'Punktipilv',
    fifthPickServiceModalExplanation: 'Aeromõõdistatud ala punktipilv.<br>Edastatakse: värviline punktipilv.',
    sixthPickServiceModalTitle: '3D mudel',
    sixthPickServiceModalExplanation: 'Aeromõõdistatud ala 3D võrkmudel (MESH). Võimalik nii suuremate maa-alade üldine mudel kui hoonete/rajatiste kõrge detailsusega mudel.<br>Edastatakse: 3D mudel LandXML, OBJ või sarnases formaadis.',
    seventhPickServiceModalTitle: 'Topograafiline plaan',
    seventhPickServiceModalExplanation: 'Aeromõõdistatud andmetest vektoriseeritud andmed CAD tarkvaras kasutamiseks. 2D joonise koostamisel arvestatakse MKM nõudeid, mõõtkavas 1:500- 1:5000.<br>Edastatakse: joonis DWG failina.',
    eightPickServiceModalTitle: 'SiteView',
    eightPickServiceModalExplanation: 'Drooniga aeromõõdistamine jooksvalt ehituse ajal ja võrdlemine projektsete andmetega.<br>Edastatakse: veebikeskkonnas.',
    ninthPickServiceModalTitle: 'Termopildistamine ehk termograafia',
    ninthPickServiceModalExplanation: 'Võimalik tuvastada hoonetel soojalekkeid ja tuvastada temperatuuri erinevusi pindadel.<br>Edastatakse: fotod, GeoTIFF fail või 3D mudel.',
    tenthPickServiceModalTitle: 'LIDAR mõõdistus',
    tenthPickServiceModalExplanation: 'LIDAR seadmega mõõdistamine alal. Punktipilv omab peegelduse, värvi ja kõrguse infot ning LIDARi punktipilvel on võimalus ka punktipilve klassifitseerida. Suur eelis fotogramm-meetria ees on kõrghaljastuse aluse pinna/maapinna 3D info ja näiteks õhuliinide eristamine punktipilvest.<br>Edastatakse: punktipilv, klassifitseeritud punktipilv, mudelid.',
    pickServiceModalSubmit: 'Valmis',
    // Second form continues from here
    formResolution: 'Resolutsioon:',
    formResolutionDropDownFirstPick: '1cm/piksel',
    formResolutionDropDownSecondPick: '1.5cm/piksel',
    formResolutionDropDownThirdPick: '2cm/piksel',
    formResolutionDropDownFourthPick: '2.5cm/piksel',
    formCoordinateSystem: 'Koordinaatsüsteem:',
    // Third part of the form
    formThirdTitle: 'Kaardi joonistamine',
    drawingHelpExplanationFirstPart: 'Vajutage kaardile, et alustada soovitud ala märkimisega.',
    drawingHelpExplanationSecondPart: 'Märkimise lõpetamiseks vajutage viimasel punktil 2x.',
    drawingHelpFirstClick: 'Ala märkimise lõpetamiseks vajutage viimasel punktil 2x',
    drawingHelpDoubleClickTitle: 'Ala joonistatud',
    drawingHelpChangeExplanation: 'Muutmiseks venitage ala piire, või',
    drawingHelpChangeButtonText: 'vajutage siia, et alustada uuesti',
    formSubmit: 'Esita päring',
    // Submit button is clicked, buefy modal text
    failedModalTitle: 'Midagi läks valesti...',
    failedModalText: 'Palun veenduge, et täitsite kõik kohustuslikud väljad korrektselt!',
    failedModalButtonText: 'Proovin uuesti',
    successModalTitle: 'Täname!',
    successModalText: 'Teie päring on edastatud. Võtame Teiega ühendust esimesel võimalusel!',
    sucessModalButtonText: 'Jätkan',
    // Form footer
    formContactUs: 'Võta meiega ühendust:'
  },
  eng: {
    // Show and hide form buttons
    formShowButton: 'Show form',
    formHideButton: 'Hide form',
    // First part of the form
    formFirstTitle: 'Contact information',
    formPersonName: 'Your name:',
    formCompanyName: 'Company name:',
    formEmail: 'E-mail address:',
    formPhoneNumber: 'Phone number:',
    formTextboxPlaceholder: 'Additional information related to the request',
    formRequiredStar: 'Required fields',
    // Second part of the form
    formSecondTitle: 'Service selection',
    formDesiredService: 'Desired services:',
    formPickService: 'Pick service:',
    formChangePickedService: 'Change service',
    // Extras
    selectedExtras: 'Selected additional services',
    // Pick service modal
    firstPickServiceModalTitle: 'Photo of the object / progress photo',
    firstPickServiceModalExplanation: 'Drone progress photos will be taken to capture situations/stages during construction. Timeline of photos will be delivered.',
    secondPickServiceModalTitle: 'Coordinated photos of the object',
    secondPickServiceModalExplanation: 'Aerial imaging with a drone for photogrammetric processing. Geopositioned images will be delivered.',
    thirdPickServiceModalTitle: 'Orthophotomics',
    thirdPickServiceModalExplanation: 'Photogrammetric aero surveying and photo processing into an orthophoto mosaic. <br> The geopositioned orthophotomosaic GeoTiff file will be delivered.',
    thirdPickServiceModalCheckbox: 'WMS service',
    fourthPickServiceModalTitle: 'DEM photo',
    fourthPickServiceModalExplanation: 'Digital planar altitude model of the aero-measured area (based on the DEM model). <br> The geopositioned Tiff file with altitude information will be delivered.',
    fifthPickServiceModalTitle: 'Point cloud',
    fifthPickServiceModalExplanation: 'Aerial-measured area point cloud. <br> A colored dot cloud will be delivered.',
    sixthPickServiceModalTitle: '3D model',
    sixthPickServiceModalExplanation: 'Aerial Measured Area 3D Network Model (MESH). Both a general model for larger areas and a high-detail model for buildings / structures are possible. The model is presented in LandXML, OBJ or similar format. <br> <br> NB! when ordering the model, specify the purpose and, if possible, the level of detail required.',
    seventhPickServiceModalTitle: 'Topographic plan',
    seventhPickServiceModalExplanation: 'Vectorized data from aeronautical data for use in CAD software. When compiling a 2D drawing, the requirements of the MEAC are taken into account, on a scale of 1: 500-1: 5000. The drawing will be delivered as a DWG file. <br> <br> NB! This is not a Geodetic Master Plan',
    eightPickServiceModalTitle: 'SiteView',
    eightPickServiceModalExplanation: 'Drone aerial measurement during construction and comparison with design data. <br>The data will be delivered in our online environment.',
    ninthPickServiceModalTitle: 'Thermal imaging',
    ninthPickServiceModalExplanation: 'It is possible to detect heat leaks in buildings and to detect temperature differences on surfaces.<br>Photos, GeoTIFF file or 3D model will be delivered.',
    tenthPickServiceModalTitle: 'LIDAR survey',
    tenthPickServiceModalExplanation: 'The area will be surveyed with a LIDAR device. The point cloud includes reflection, color, and height data, optionally also a classified point cloud. A major advantage over photogrammetry is acquiring the 3D data for high greenery surfaces / grounds, and for example overhead powerlines.<br>Point cloud, classified point cloud, models will be delivered.',
    pickServiceModalSubmit: 'Done',
    // Second form continues from here
    formResolution: 'Resolution:',
    formResolutionDropDownFirstPick: '1cm/pixel',
    formResolutionDropDownSecondPick: '1.5cm/pixel',
    formResolutionDropDownThirdPick: '2cm/pixel',
    formResolutionDropDownFourthPick: '2.5cm/pixel',
    formCoordinateSystem: 'Coordinate system:',
    // Third part of the form
    formThirdTitle: 'Draw a map',
    drawingHelpExplanationFirstPart: 'Click on the map to start marking the desired area.',
    drawingHelpExplanationSecondPart: 'To end marking the area, press at the last point 2x.',
    drawingHelpFirstClick: 'To end marking the area, press at the last point 2x',
    drawingHelpDoubleClickTitle: 'Area drawn',
    drawingHelpChangeExplanation: 'To change, stretch the area boundaries, or',
    drawingHelpChangeButtonText: 'click here to start again',
    formSubmit: 'Submit request',
    // Submit button is clicked, buefy modal text
    failedModalTitle: 'Something went wrong...',
    failedModalText: 'Please make sure you fill in all required fields correctly!',
    failedModalButtonText: 'Try again',
    successModalTitle: 'Thank you!',
    successModalText: 'Your request has been submitted. We will contact you as soon as possible!',
    sucessModalButtonText: 'Continue',
    // Form footer
    formContactUs: 'Contact us'
  }
}

const i18n = new VueI18n({
  locale: 'est', // set locale
  fallbackLocale: 'eng', // set fallback locale
  messages // set locale messages
})

export default i18n
