import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    server: 'https://3di.pilves.cloud/index.php',
    area: null,
    drawing: false,
    selectedLanguage: 'est'
  },
  mutations: {
    setDrawing (state, payload) {
      state.drawing = payload
    },
    setArea (state, payload) {
      state.area = payload
    }
  },
  actions: {
  },
  getters: {
  },
  modules: {}
})
