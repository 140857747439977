import Vue from 'vue'
import store from './store'
import App from './App.vue'
import VueLayers from 'vuelayers'
import Buefy from 'buefy'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VModal from 'vue-js-modal'
import i18n from './plugins/i18n'

import 'buefy/dist/buefy.css'
import 'vuelayers/lib/style.css'
import './assets/style.css'

// import { register } from 'ol/proj/proj4'
// import proj4 from 'proj4'

// proj4.defs('EPSG:3301', '+proj=lcc+lat_1=59.33333333333334+lat_2=58+lat_0=57.51755393055556+lon_0=24+x_0=500000+y_0=6375000+ellps=GRS80+towgs84=0,0,0,0,0,0,0+units=m+no_defs')
// register(proj4)

Vue.use(VueAxios, axios)
Vue.use(Buefy)
Vue.use(VueLayers, { dataProjection: 'EPSG:4326' })
// Vue.use(VueLayers, { dataProjection: 'EPSG:3301' })
Vue.use(VModal)

Vue.config.productionTip = false

new Vue({
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
