<template>
  <div id="app">
    <!-- Brand -->
    <div id="brand">
      <img src="@/assets/logo.png"/>
      <LanguageSelect class="languages is-pulled-right" />
    </div>
    <!-- Map area -->
    <div style="position: absolute;">
      <!-- Map -->
      <Map></Map>
      <!-- Form -->
      <Form></Form>
    </div>

  </div>
</template>

<script>
import Map from './components/Map.vue'
import Form from './components/Form.vue'
import LanguageSelect from './components/LanguageSelect'

export default {
  name: 'App',
  components: {
    LanguageSelect, Map, Form
  }
}
</script>
